import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import ButtonGrey from "../../components/ButtonGrey";
import { colors, breakpoints, fonts } from "../../style-utilities/variables";
import ReactHtmlParser from "react-html-parser";

const Div = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 1em;
  padding-right: 1em;

  @media only screen and (min-width: 768px) {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  @media only screen and (min-width: 1025px) {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
  }
  .info-container {
    padding: 1em;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    h3 {
      font-size: 22px;
    }
  }
`;
const StyledLocationContainer = styled.div`
  width: 100%;
  margin-bottom: 1em;
  border-right: 2px solid ${colors.secondaryGray};
  border-bottom: 2px solid ${colors.secondaryGray};
  border-left: 2px solid ${colors.secondaryGray};
  a {
    text-decoration: none;
  }
  @media (min-width: ${breakpoints.desktop}) {
    width: 48%;
    margin-right: 2%;
    margin-bottom: 2%;
  }
`;

const StyledImageContainer = styled.div`
  min-height: 16rem;
  background-image: url(${props => props.backgroundimage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

const Location = () => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressWpLocations(sort: { fields: slug, order: ASC }) {
          edges {
            node {
              slug
              title
              content
              acf {
                address
                phone
                hours
              }
              better_featured_image {
                source_url
              }
            }
          }
        }
      }
    `}
    render={data => {
      const locations = data.allWordpressWpLocations.edges;
      // fcn to filter for city:  st-louis dallas portland
      const filterForCity = (arr, city) => {
        return arr.filter(item => item.node.slug === city);
      };
      const stLouis = filterForCity(locations, "st-louis");
      const dallas = filterForCity(locations, "dallas");
      const portland = filterForCity(locations, "portland");
      // fcn to filter out the cities above and then sort alphabetically
      const filteredRemaining = locations.filter(
        function(e) {
          return this.indexOf(e.node.slug) < 0;
        },
        ["st-louis", "dallas", "portland"]
      );
      const newLocations = [
        ...stLouis,
        ...dallas,
        ...portland,
        ...filteredRemaining
      ];
      return (
        <Div>
          {newLocations.map(item => {
            return (
              <StyledLocationContainer>
                <Link to={"contact/" + item.node.slug}>
                  <StyledImageContainer
                    backgroundimage={item.node.better_featured_image.source_url}
                  ></StyledImageContainer>
                </Link>
                <div className="info-container">
                  <div>
                    <h3>{item.node.title}</h3>
                  </div>
                  <div>{ReactHtmlParser(item.node.acf.address)}</div>
                  <div>
                    <ButtonGrey
                      textLabel="View Location"
                      pageLink={"/contact/" + item.node.slug}
                    >
                      VIEW LOCATION
                    </ButtonGrey>
                  </div>
                </div>{" "}
              </StyledLocationContainer>
            );
          })}
        </Div>
      );
    }}
  />
);

export default Location;
