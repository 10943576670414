import React, { Component } from 'react';
//FORMIK SPECIFIK
// import 'whatwg-fetch';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
//STYLE
import styled from 'styled-components';
import { colors, fonts, breakpoints } from '../../style-utilities/variables';

const StyledFormikForm = styled.div`
  .success-message {
    margin-top: 6em;
  }
  select option[selected] {
    background-color: ${colors.black};
  }
  /* select label is used outside of form-group to handle the toggling of the arrow */
  .selectLabel {
    color: ${colors.black};
    font-weight: bold;
    white-space: nowrap;
    font-size: 14px;
  }
  label {
    color: ${colors.black};
    font-weight: bold;
    margin-bottom: 0rem !important;
    font-size: 14px;
  }
  .form-group {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 14px;
    line-height: 1.5rem;
    textarea,
    input[type] {
      /* Must use input[type=text] selector to remove iOS box-shadow styles */
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;

      border: 1px solid #cfd4d9;
      padding: 4px 10px;
      border-radius: 4px;
      ::placeholder {
        font-style: italic;
      }
    }
  }
  select {
    border: 1px solid #cfd4d9;
    background: white;
    position: relative;
    border-radius: 4px;
    font-size: 14px;
    font-style: italic;
    color: ${colors.primaryGray};
    display: inline-block;
    line-height: 1.5em;
    padding: 6px 10px;
    margin: 0;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: linear-gradient(45deg, transparent 50%, black 50%),
      linear-gradient(135deg, black 50%, transparent 50%),
      linear-gradient(to right, white, white);
    background-position: calc(100% - 20px) 13px, calc(100% - 15px) 13px, 100% 0;
    background-size: 5px 5px, 5px 5px, 2.5em 2.65em;
    background-repeat: no-repeat;
  }

  select:focus {
    background-image: linear-gradient(45deg, white 50%, transparent 50%),
      linear-gradient(135deg, transparent 50%, white 50%),
      linear-gradient(to right, white, white);
    background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em, 100% 0;
    background-size: 5px 5px, 5px 5px, 2.5em 2.65em;
    background-repeat: no-repeat;
    border-color: grey;
    outline: 0;
  }
  select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 ${colors.black};
  }
  .button-align {
    text-align: center;
    width: 100%;
  }
  #message {
    width: 100%;
    textarea {
      height: 70px;
      font-size: 14px;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }

  .label-error {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .field-error {
      font-size: 0.8rem;
      float: right;
      margin-left: 8px;
      color: ${colors.primary};
    }
  }

  .submit-btn {
    background-color: ${colors.primary};
    border: 0;
    color: ${colors.white};
    border-radius: 25px;
    padding: 12px 25px;
    font-family: Verdana;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 3px;
    cursor: pointer;
    align-self: center;
    justify-self: center;
  }
  .submit-btn:hover {
    //border: 1px solid #9c2627;
    background-color: #9c2627;
    transition: 0.3s all ease;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    select::-ms-expand {
      display: none;
    }
  }
  .emailphone {
    /* this class is used to check the form for spam bots */
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    z-index: -1;
  }
`;

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  company: '',
  message: '',
  options: 'option1'
};
const Fieldset = ({ name, label, children, classname, id, ...rest }) => (
  <div className={classname} id={id}>
    <div className="label-error">
      <label htmlFor={name}>
        {label}
        <ErrorMessage name={name} component="div" className="field-error" />
      </label>
    </div>
    <Field id={name} name={name} {...rest}>
      {children}
    </Field>
  </div>
);

class FormikContactForm extends Component {
  state = {
    isBrowser: true,
    windowObject: null,
    success: false,
    successMsg: ''
  };

  componentDidMount() {
    this.setState({
      isBrowser: typeof window !== 'undefined'
    });
    if (this.state.isBrowser) {
      this.setState({ windowObject: window });
    }
  }

  render() {
    return (
      <StyledFormikForm>
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            options: Yup.string()
              .oneOf(
                [
                  'Agile CRM Optimization',
                  'NewBusiness',
                  'CRMLoyalty',
                  'ChannelPartnerSolutions',
                  'MediaActivation',
                  'MarketingTechnology',
                  'PressInquiries',
                  'Careers',
                  'General',
                  'Localized Sales Acceleration',
                  'Scaled Digital Platform Solutions',
                  'Customer Journey Enhancement'
                ],
                'Please complete this required field.'
              )
              .required('Please complete this required field.'),
            firstName: Yup.string()
              .trim()
              .required('Please complete this required field.'),
            lastName: Yup.string()
              .trim()
              .required('Please complete this required field.'),
            company: Yup.string()
              .trim()
              .required('Please complete this required field.'),
            email: Yup.string()
              .trim()
              .email('Please complete this required field.')
              .required('Please complete this required field.'),
            message: Yup.string()
              .trim()
              .max(
                1500,
                'Ooops.Please enter a message shorter than 1500 characters.'
              )
              .ensure()
          })}
          onSubmit={(values, actions) => {
            fetch(
              'https://api.hsforms.com/submissions/v3/integration/submit/3224127/73302b1e-dc7d-4f75-90aa-3a3e16ea83c4',
              {
                method: 'POST',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  context: {
                    pageUri: this.state.windowObject.location.href,
                    pageName: 'Contact Us | Ansira Engagement Marketing'
                  },
                  fields: [
                    {
                      name: 'email',
                      value: values.email
                    },
                    {
                      name: 'firstname',
                      value: values.firstName
                    },
                    {
                      name: 'lastname',
                      value: values.lastName
                    },
                    {
                      name: 'company',
                      value: values.company
                    },
                    {
                      name: 'how_can_we_help_',
                      value: values.options
                    },
                    {
                      name: 'message',
                      value: values.message
                    }
                  ]
                })
              }
            )
              .then(response => {
                return response.json();
              })
              .then(data => {
                if (data) {
                  this.setState({
                    success: true
                  });
                }
              });

            //done submitting, set submitting to false
            actions.setSubmitting(false);
            actions.resetForm(initialValues);
          }}
        >
          {({ errors, values, actions }) => (
            <div>
              <Form style={{ display: this.state.success ? 'none' : 'block' }}>
                <label htmlFor="options" className="selectLabel">
                  How Can We Help?*
                </label>
                <Fieldset
                  classname="form-group"
                  id="select"
                  name="options"
                  component="select"
                  type="text"
                >
                  <option value="option1" disabled>
                    — Please Make a Selection —
                  </option>
                  <option value="Agile CRM Optimization">Agile CRM Optimization</option>
                  <option value="ChannelPartnerSolutions">Channel Partner Solutions</option>
                  <option value="CRMLoyalty">CRM + Loyalty</option>
                  <option value="Customer Journey Enhancement">Customer Journey Enhancement</option>
                  <option value="Localized Sales Acceleration">Localized Sales Acceleration</option>
                  <option value="MarketingTechnology">Martech Integrations and Partnerships</option>
                  <option value="MediaActivation">Media Activation</option>
                  <option value="Scaled Digital Platform Solutions">Scaled Digital Platform Solutions</option>
                  <option value="General">General</option>
                  <option value="NewBusiness">New Business</option>
                  <option value="Careers">Careers</option>
                  <option value="PressInquiries">Press Inquiries</option>
                </Fieldset>
                <div className="sideBySide">
                  <Fieldset
                    classname="form-group names"
                    name="firstName"
                    label="First Name*"
                    type="text"
                    placeholder=""
                  />
                  <Fieldset
                    classname="form-group names"
                    name="lastName"
                    label="Last Name*"
                    type="text"
                    placeholder=""
                  />
                </div>

                <Fieldset
                  classname="form-group"
                  name="company"
                  label="Company Name*"
                  type="text"
                  placeholder=""
                />
                <div className="sideBySide">
                  <Fieldset
                    classname="form-group"
                    name="email"
                    label="Email*"
                    type="email"
                    placeholder=""
                  />
                </div>

                <Fieldset
                  classname="form-group"
                  id="message"
                  name="message"
                  label="Message"
                  component="textarea"
                  type="text"
                  rows="6"
                />
                <div className="button-align">
                  <button type="submit" className="submit-btn">
                    GET IN TOUCH
                  </button>
                </div>
              </Form>
            </div>
          )}
        </Formik>
        <p
          className="success-message"
          style={{ display: this.state.success ? 'block' : 'none' }}
        >
          Thank you, we have received your message and will get back to you
          ASAP. Your friends @Ansira.
        </p>
      </StyledFormikForm>
    );
  }
}

export default FormikContactForm;
