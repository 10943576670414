import React, { Component } from 'react';
import { Helmet } from 'react-helmet';


//FORMIK SPECIFIK:
import FormikContactForm from './FormikContactForm';

class ContactForm extends Component {
  render() {
    return (
      <section id="contact-section">
        <FormikContactForm />
        <Helmet>
          <script type="text/javascript">llfrmid=17596</script>
          <script type="text/javascript" src="https://tracking.leadlander.com/formalyze_init.js"></script>
          <script type="text/javascript" src="https://tracking.leadlander.com/formalyze_call_secure.js"></script>
        </Helmet>
      </section>
    );
  }
}
export default ContactForm;
