import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { colors, breakpoints, fonts } from "../style-utilities/variables";
import ReactHtmlParser from "react-html-parser";
// import StickyBox from "react-sticky-box";

//SECTIONS
import ContactForm from "../components/Contact/ContactForm";
import Location from "../components/Contact/Location";
import Hero from "../components/Hero";
import SEO from "../components/SEO";
import DefaultSharingImage from "./../static/ansira-logo-sharing.png";

const StyledContact = styled.main`
  margin: 0.75em auto;
  margin-top: 0;
  max-width: 1500px;
  position: relative;
  .form-mobile {
    padding-right: 1em;
    margin-bottom: 6em;
  }
  .right {
    display: none;

  }
  .intro {
    padding-left: 1em;
    padding-right: 1em;
    p {
      font-size: 26px;
      .mobile-break {
        display: block;
      }
    }
    a {
      display: table;
      font-size: 48px;
      color: ${colors.primary};
      font-weight: bold;
    }
  }
  @media (min-width: ${breakpoints.tablet}) {
    display: flex;
    .form-mobile {
      display: none;
    }
    .intro {
      padding-left: 2.5em;
      p {
        margin-bottom: 6em;
        .mobile-break {
          display: inline;
        }
      }
    }
    .left {
      width: 66%;
    }
    .right {
      display: block;
      width: 33%;
      padding-right: 2em;
      #contact-section {
        top: 50px;
        padding-top: 5em;
        position: sticky;
      }
    }
  }
  @media (min-width: ${breakpoints.desktop}) {
    .intro {
      padding-left: 4.5em;
    }
    .right {
      /* right: 4.5em; */
    }
  }
`;

const Contact = ({ data }) => {
  const contact_page = data.wordpressPage;

  return (
    <StyledContact className="animate">
      <SEO
        isBlogPost={false}
        postData={data.wordpressPage}
        postImage={DefaultSharingImage}
      />

      <div className="left">
        <Hero
          headline={contact_page.acf.hero_headline}
          sub={contact_page.acf.hero_sub_headline}
          textcolor={colors.white}
          bgcolor={colors.black}
        />

        <div className="intro">
          {ReactHtmlParser(contact_page.acf.intro_copy)}
          <div className="form-mobile">
            <ContactForm />
          </div>
          <h3>Our Locations</h3>
        </div>
        <Location />
      </div>
      <div className="right">
        <ContactForm />
      </div>
    </StyledContact>
  );
};

export const query = graphql`
  query {
    wordpressPage(slug: { eq: "contact" }) {
      content
      slug
      title
      type
      content
      acf {
        seo_title
        seo_canonical
        seo_description
        hero_headline
        hero_sub_headline
        intro_copy
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
      }
    }
  }
`;

export default Contact;
